import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Dialog } from 'sw-ui';

import CircularLoaders from '../../../components/loaders';
import Input from '../../../components/input';
import { TicketWarnings } from './TicketWarnings';
import SelectCancelAir from './form/selectCancelAir';
import CancelSegmentAir from './form/cancelSegmentAir';
import { CurrencyInfoBlock } from './hotel/components/CurrencyInfoBlock';
import Checkbox from '../../../components/checkbox';

import { mapAirVoucherToAirTickets } from '../../../bi/services/air/mapper';

import { parseJson, stringifyJson } from '../../../bi/utils/json';

import { AIRLINECHANGERATE, TRAINCANCELRATE } from '../../../constants/rateTypes';
import { TRAIN_TARIFFS } from '../../../bi/constants/train';
import COMPONENTS from '../../../bi/constants/components';
import { SERVICETYPE } from '../../../bi/constants/serviceType';
import {
  CANCELAIRFIELDS,
  MODELS,
  STATUSES_ERROR_CURRENCY,
  TEXT_ERROR_CURRENCY,
  TRIPSLABELS,
} from '../../../bi/constants/trips';
import { HOTEL_PROVIDER_VALUE, CURRENCY_VALUES } from '../../../bi/constants/hotel';

import styles from '../styles/penalty.module.css';

const FULLTIMEFORMAT = 'YYYY-MM-DDTHH:mm:sszzZ';

const LABELS = {
  DESCRIPTION: 'Название для документа',
  PENALTIES: 'Штраф поставщика услуг',
  TAX: 'Сбор Smartway',
  PENALTIES_CURRENCY: 'Сумма штрафа поставщика в валюте',
  CANCELORDER: 'ОТМЕНИТЬ ЗАКАЗ',
  RUB: 'рублям',
  USER_FEE_FOR_CANCEL: 'У пользователя есть сбор за отмену равный',
  PENALTY: 'Отмена со штрафом',
  NOT_PENALTY: 'Отмена без штрафа',
  PARAMS: 'Укажите параметры отмены',
  PARAMS_CELLATION: 'Параметры отмены',
  PARAMS_FINE: 'Параметры штрафа',
  WARNING: 'Обратите внимание, что при возврате нужно отменить билеты на все места, купленные по спецтарифу:',
  SW_REFUND: 'Возврат сбора SW',
  YES: 'да',
  NO: 'нет',
};

const FIELDS = {
  DESCRIPTION: 'Description',
  PENALTIES: 'Penalties',
  TAX: 'Tax',
  PENALTIES_CURRENCY: 'PenaltiesCurrency',
  BASE: 'Base',
  COMMISSION: 'Commission',
};

const FIELDSFORCANCELSEGMENT = {
  [FIELDS.PENALTIES]: FIELDS.BASE,
  [FIELDS.TAX]: FIELDS.COMMISSION,
};

const WIDTH = '450px';
const DEFAULT_BUS_RATE = 180;

const indexSegment = ({ Direction, DepartureDate }, index) => `${index}_${Direction}_${DepartureDate}`;

const preparedSegments = (routes) => {
  const segments = [];

  routes.forEach(({ Segments }, index) => Segments
    .forEach(segment => segments.push({
      Segment: segment,
      SegmentIndex: indexSegment(segment, index),
      Selected: false,
    })));

  return segments;
};

const preparedData = (data, list) => {
  const routes = [];

  data.Routes.forEach((route, index) => {
    const filteredSegments = route.Segments
      .filter(segment => list
        .filter(({ Selected }) => !Selected)
        .map(({ SegmentIndex }) => SegmentIndex)
        .includes(indexSegment(segment, index)));

    if (filteredSegments.length) {
      routes.push({
        ...route,
        Segments: filteredSegments,
      });
    }
  });

  return routes;
};

const preparedDate = date => (typeof date === 'string' ? moment(date) : date);

const preparedChangedSegments = segments => segments.map((segment) => {
  const { DepartureDate, ArrivalDate } = segment;

  return {
    ...segment,
    ArrivalDate: preparedDate(ArrivalDate).format(FULLTIMEFORMAT),
    DepartureDate: preparedDate(DepartureDate).format(FULLTIMEFORMAT),
  };
});

const preparedRoutes = routes => routes.map((route) => {
  const { Segments } = route;
  const segments = preparedChangedSegments(Segments);

  return {
    ...route,
    Segments: segments,
  };
});

class Penalty extends Component {
  static propTypes = {
    item: PropTypes.object,
    changeRate: PropTypes.object,
    getCancelTripInfo: PropTypes.func,
    cancelCurrencyAmount: PropTypes.func.isRequired,
    accountService: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
    tickets: PropTypes.array,
    cancelLoading: PropTypes.bool,
  };

  static defaultProps = {
    item: null,
    getCancelTripInfo: () => {},
    changeRate: {},
    tickets: [],
    cancelLoading: false,
  };

  constructor(props) {
    super(props);

    const { item, accountService } = this.props;
    const itemDetails = item.JsonData ? JSON.parse(item.JsonData) : null;
    const busCommission = itemDetails ? itemDetails.PriceDetails.Commission : null;
    const defPriceBusCommission = !busCommission ? DEFAULT_BUS_RATE : busCommission;
    const isAir = item.ServiceType === SERVICETYPE.AIR;
    const isBus = item.ServiceType === SERVICETYPE.BUS;
    const { currencyInfo } = accountService.get();
    const defaultPenalties = !isBus ? 0 : defPriceBusCommission;

    this.state = {
      penalty: {
        Penalties: 0,
        Tax: defaultPenalties,
        PenaltiesCurrency: 0,
        Description: TRIPSLABELS.PENALTY_FOR_CANCELLATION,
      },
      CancelSegment: {
        Segments: [],
        Penalty: {
          Base: 0,
          Commission: defaultPenalties,
        },
        Refund: {
          Base: 0,
          Tax: 0,
          Taxes: 0,
          Fee: 0,
          Commission: 0,
        },
      },
      VatDetails: isAir ? MODELS.VatDetails.map(i => ({ ...i })) : {},
      JsonData: isAir ? mapAirVoucherToAirTickets(parseJson(item.JsonData), props.item)[0] : {},
      cancelSegmentAir: '',
      segments: isAir ? preparedSegments(parseJson(item.JsonData).Routes) : {},
      allCancel: false,
      currency: CURRENCY_VALUES.USD,
      currencyInfo,
      textFailCurrency: '',
    };
  }

  componentDidMount = () => {
    const { CompanyId, TripItemId, ServiceType, ProviderName } = this.props.item;
    const { getCancelTripInfo, accountService } = this.props;
    const { currency } = this.state;

    if (ServiceType === SERVICETYPE.TRAIN) {
      getCancelTripInfo(CompanyId, TripItemId);
    }

    if (ProviderName === HOTEL_PROVIDER_VALUE.expedia) {
      accountService.getExchangeCurrency(currency);
    }

    this.unsubscribe = accountService.subscribe(this.update);
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({ currencyInfo }) => {
    this.setState({ currencyInfo });
  };

  renderCoupeWarning = () => {
    const { item, tickets } = this.props;
    const data = JSON.parse(item.JsonData);

    if (
      item.ServiceType !== SERVICETYPE.TRAIN ||
      tickets.length <= 1 ||
      (data.Tariff &&
        data.Tariff !== TRAIN_TARIFFS.KUPEK)
      ) {
      return null;
    }

    return (
      <TicketWarnings
        text={ LABELS.WARNING }
        item={ item }
        tickets={ tickets }
      />
    );
  };

  defaultDataCancel = () => {
    const { item } = this.props;
    const { penalty } = this.state;

    const { Penalties, Tax } = penalty;
    const { OrderTripItemId, ServiceType, ProviderName, UserAnalytics } = item;

    return {
      OrderTripItemId,
      Status: 2,
      Description: penalty.Description,
      ServiceType,
      Penalties: [{
        ...penalty,
        Penalties: parseFloat(Penalties, 10) || 0,
        Tax: parseFloat(Tax, 10) || 0,
      }],
      ReturnFunds: [],
      Surcharges: [],
      ProviderName,
      UserAnalytics,
    };
  };

  preparedAirCancel = () => {
    const { item } = this.props;
    const { penalty, cancelSegmentAir, segments, CancelSegment, allCancel, VatDetails, JsonData } = this.state;
    const { OrderTripItemId, ServiceType, UserAnalytics } = item;
    const routes = preparedData(JsonData, segments);

    if (cancelSegmentAir === CANCELAIRFIELDS.SEGMENTS && routes.length && !allCancel) {
      const vat = VatDetails.filter(({ Rate }) => !!Rate);
      const changedRoutes = preparedRoutes(routes);

      const json = {
        ...JsonData,
        Routes: changedRoutes,
        VatDetails: vat,
        Travellers: JsonData.Travellers.Id,
        Passports: {
          EmployeeId: JsonData.Travellers.Id,
          PassportId: typeof JsonData.Passport === 'object' ? JsonData.Passport.PassportId : JsonData.Passport,
        },
      };

      const { Base, Commission } = CancelSegment.Penalty;

      return {
        OrderTripItemId,
        Status: 1,
        Description: penalty.Description,
        ServiceType,
        JsonData: stringifyJson([json]),
        CancelSegment: {
          ...CancelSegment,
          Penalty: {
            ...CancelSegment.Penalty,
            Base: parseFloat(Base, 10) || 0,
            Commission: parseFloat(Commission, 10) || 0,
          },
        },
        Penalties: [],
        ReturnFunds: [],
        Surcharges: [],
        UserAnalytics,
      };
    }

    return this.defaultDataCancel();
  };

  preparedCurrencyData = () => {
    const { item } = this.props;
    const { penalty, currencyInfo } = this.state;

    const { Penalties, Tax, PenaltiesCurrency } = penalty;
    const { OrderTripItemId, ProviderName } = item;
    const { CurrencyName, CurrencyValue, CurrencyValueWithMultiplier, Multiplier, DateUTC } = currencyInfo;

    const currency = {
      Name: CurrencyName,
      Value: CurrencyValue,
      ValueWithMultiplier: CurrencyValueWithMultiplier,
      Multiplier,
      DateUTC,
    };

    return {
      TripItemId: OrderTripItemId,
      Provider: ProviderName,
      ProviderPenalty: PenaltiesCurrency,
      SwCommission: Tax,
      SwBase: Penalties,
      Currency: currency,
    };
  }

  handleChangeInput = (field, value) => this.setState({
    penalty: {
      ...this.state.penalty,
      [field]: value,
    },
    CancelSegment: {
      ...this.state.CancelSegment,
      Penalty: {
        ...this.state.CancelSegment.Penalty,
        [FIELDSFORCANCELSEGMENT[field]]: value,
      },
    },
  });

  handleChangeStep = value => this.setState({
    cancelSegmentAir: value,
  });

  handleConfirm = async () => {
    const {
      item: { ServiceType, ProviderName },
      onChange,
      cancelCurrencyAmount,
    } = this.props;

    const tripItem = ServiceType === SERVICETYPE.AIR ? this.preparedAirCancel() : this.defaultDataCancel();

    if (ProviderName === HOTEL_PROVIDER_VALUE.expedia) {
      const res = await cancelCurrencyAmount(this.preparedCurrencyData());

      if (res.OrderState !== STATUSES_ERROR_CURRENCY.OK) {
        return this.setState({
          textFailCurrency: TEXT_ERROR_CURRENCY[res.OrderState],
        });
      }

      const {
        Currency: { Name, Value, Multiplier, DateUTC },
        Price: { Base, Total, Commission, MF },
        PriceInCurrency: {
          Base: BaseInCurrency,
          Total: TotalInCurrency,
          Commission: CommissionInCurrency,
          MF: MFInCurrency,
        },
      } = res;

      const updCurrency = {
        CurrencyName: Name,
        CurrencyValue: Value,
        CurrencyMultiplier: Multiplier,
        CurrencyDateUTC: DateUTC,
        Base,
        BaseInCurrency,
        Total,
        TotalInCurrency,
        Commission,
        CommissionInCurrency,
        MF,
        MFInCurrency,
      };

      onChange({ ...this.defaultDataCancel(), CurrencyData: updCurrency });
      return this.handleChangeStep('');
    }

    onChange(tripItem);
    return this.handleChangeStep('');
  };

  handleChangePriceInput = (e, field, value) => {
    const part = field.split('.');

    this.setState({
      CancelSegment: {
        ...this.state.CancelSegment,
        Refund: {
          ...this.state.CancelSegment.Refund,
          [part[1]]: Number(value),
        },
      },
    });
  };

  handleChangeVatDetails = (ticketInd, rate, field, value) => {
    this.setState((state) => {
      const clonedState = { ...state };

      const indexOfVatElement = clonedState.VatDetails.findIndex(({ Rate }) => Rate === rate);
      if (typeof indexOfVatElement === 'undefined') return clonedState;

      clonedState.VatDetails[indexOfVatElement][field] = Number(value);

      return clonedState;
    });
  };

  handleChangeCheckbox = (segments, allCancel) => {
    const names = [];

    segments.forEach(({ Selected, Segment: { DepartureCity, ArrivalCity } }) => {
      if (Selected) {
        names.push(`${DepartureCity} - ${ArrivalCity}`);
      }
    });

    this.setState({
      segments,
      allCancel,
      CancelSegment: {
        ...this.state.CancelSegment,
        Segments: names,
      },
    });
  };

  handleCheckboxesPenalties = (isFreeCancelation) => {
    const { item } = this.props;
    const itemDetails = item.JsonData ? JSON.parse(item.JsonData) : null;
    const busCommission = itemDetails ? itemDetails.PriceDetails.Commission : null;
    const defPriceBusCommission = !busCommission ? DEFAULT_BUS_RATE : busCommission;

    const priceOfCancelation = isFreeCancelation ? 0 : defPriceBusCommission;

    this.setState({
      ...this.state,
      isFreeCancelation,
      CancelSegment: { Penalty: { Base: 0, Commission: priceOfCancelation } },
      penalty: { ...this.state.penalty, Tax: priceOfCancelation },
    });
  };

  renderButton = () => (
    <div className={ `${styles.row} ${styles.penalty_action}` }>
      <Button
        label={ LABELS.CANCELORDER }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ this.handleConfirm }
      />
    </div>
  );


  renderRefundSmartwayCheckboxes = () => {
    const isBus = this.props.item.ServiceType === SERVICETYPE.BUS;
    const { isFreeCancelation = false } = this.state;

    if (!isBus) return null;

    return (
      <div>
        <div className={ `${styles.row} ${styles.penalty__cancel_title}` }>{ LABELS.SW_REFUND }</div>
        <Checkbox
          field={ LABELS.YES }
          label={ LABELS.YES }
          value={ isFreeCancelation }
          onChange={ () => this.handleCheckboxesPenalties(!isFreeCancelation) }
        />
        <Checkbox
          field={ LABELS.NO }
          label={ LABELS.NO }
          value={ !isFreeCancelation }
          onChange={ () => this.handleCheckboxesPenalties(!isFreeCancelation) }
        />
      </div>
    );
  };

  renderRateAlert = (changeRate, serviceType) => {
    const { airline: { Change }, train: { Cancel } } = changeRate;

    const rateAlertHtml = (rates, rate) => (
      <div className={ `${styles.row} ${styles['rate-alert']}` }>
        {LABELS.USER_FEE_FOR_CANCEL} {rates[rate]} {LABELS.RUB}.
      </div>
    );

    if (serviceType === SERVICETYPE.AIR && AIRLINECHANGERATE[Change]) {
      return rateAlertHtml(AIRLINECHANGERATE, Change);
    }

    if ((serviceType === SERVICETYPE.TRAIN || serviceType === SERVICETYPE.AEROEXPRESS) && TRAINCANCELRATE[Cancel]) {
      return rateAlertHtml(TRAINCANCELRATE, Cancel);
    }

    return null;
  }

  renderDefault = () => {
    const { item, changeRate } = this.props;
    const { currencyInfo, penalty, textFailCurrency } = this.state;
    const { Tax, Penalties, PenaltiesCurrency, Description } = penalty;
    const isBus = item.ServiceType === SERVICETYPE.BUS;
    const title = isBus ? LABELS.PARAMS_CELLATION : LABELS.PARAMS_FINE;

    const renderTax = () => (item.ServiceType !== SERVICETYPE.EVENT && !isBus) && (
      <div className={ styles.row }>
        <Input
          field={ FIELDS.TAX }
          value={ Tax }
          label={ LABELS.TAX }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          onChange={ (e, field, value) => this.handleChangeInput(field, value) }
        />
      </div>
    );

    const renderCurrency = () => {
      if (item.ProviderName !== HOTEL_PROVIDER_VALUE.expedia) return null;

      return (
        <div className={ styles.row }>
          <CurrencyInfoBlock currency={ currencyInfo } />
        </div>
      );
    };

    const renderPenaltiesCurrency = () => {
      if (item.ProviderName !== HOTEL_PROVIDER_VALUE.expedia) return null;

      return (
        <div className={ styles.row }>
          <Input
            field={ FIELDS.PENALTIES_CURRENCY }
            value={ PenaltiesCurrency }
            label={ LABELS.PENALTIES_CURRENCY }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
        </div>
      );
    };

    const renderErrorTextCurrency = () => {
      if (!textFailCurrency) return null;

      return <p className={ styles.notification }>{ textFailCurrency }</p>;
    };

    return (
      <div className={ `${styles.form}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          { title }
        </div>
        { renderCurrency() }
        <div className={ styles.row }>
          <Input
            field={ FIELDS.DESCRIPTION }
            value={ Description }
            label={ LABELS.DESCRIPTION }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
        </div>
        <div className={ styles.row }>
          <Input
            field={ FIELDS.PENALTIES }
            value={ Penalties }
            label={ LABELS.PENALTIES }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
        </div>
        { this.renderRateAlert(changeRate, item.ServiceType) }
        { renderTax() }
        { this.renderRefundSmartwayCheckboxes() }
        { renderPenaltiesCurrency() }
        { renderErrorTextCurrency() }
      </div>
    );
  };

  renderFullDefault = () => (
    <div>
      { this.renderDefault() }
      { this.renderCoupeWarning() }
      { this.renderButton() }
    </div>
  );

  renderCancelSegmentAir = () => {
    const { item } = this.props;
    const { CancelSegment, VatDetails, segments } = this.state;

    return (
      <div>
        { this.renderDefault() }
        <CancelSegmentAir
          item={ item }
          segments={ segments }
          cancelSegment={ CancelSegment }
          vatDetails={ VatDetails }
          onChangePrice={ this.handleChangePriceInput }
          onChangeVatDetails={ this.handleChangeVatDetails }
          onChangeCheckbox={ this.handleChangeCheckbox }
        />
        { this.renderButton() }
      </div>
    );
  };

  renderAir = () => {
    const { item: { JsonData } } = this.props;
    const { cancelSegmentAir } = this.state;

    if (!cancelSegmentAir.length) {
      const data = parseJson(JsonData);
      const hasRoutes = data.Routes.length > 1;
      const hasSegments = data.Routes.some(({ Segments }) => Segments.length > 1);

      return hasRoutes || hasSegments ? (
        <SelectCancelAir onChangeStep={ this.handleChangeStep } />
      ) : this.renderFullDefault();
    } else if (cancelSegmentAir === CANCELAIRFIELDS.ALL) {
      return this.renderFullDefault();
    }

    return this.renderCancelSegmentAir();
  };

  renderLoading = () => (
    <div className={ `${styles.form} ${styles.dialog}` }>
      <CircularLoaders />
    </div>
    )

  render() {
    const { item: { ServiceType }, toggleDialog, cancelLoading } = this.props;
    const { loading } = this.state;

    let html = loading || cancelLoading
      ? this.renderLoading()
      : this.renderFullDefault();

    if (ServiceType === SERVICETYPE.AIR) {
      html = this.renderAir();
    }

    return (
      <Dialog width={ WIDTH } onClick={ toggleDialog }>
        <div className={ styles.penalty_wrap }>
          { html }
        </div>
      </Dialog>
    );
  }
}

export default Penalty;
